import { Address } from 'modules/Address';
import { Entity } from 'modules/App/models';
import { Contact } from 'modules/Contacts/models/models';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { Product } from 'modules/Products/models';
import { DocumentSerie } from 'modules/Settings/models';
import { TaxOption, TaxKey, TaxType } from 'modules/Taxes';
import { DataTableSort } from 'modules/Ui/DataTable/DataTable.models';
import { Currency } from 'types/currency';
import { Source } from 'types/source';

import { ReceiptStatus } from './receiptStatus';
import { StatusInfo, StatusInfoUI } from './statusInfo';

export interface Concept {
  id?: string;
  name?: string;
  description?: string;
  quantity?: number;
  unitPrice?: number;
  discountPercentage?: number;
  tax?: TaxOption;
  taxKey: TaxKey;
  total?: number;
  taxExemption?: TaxExemption | null;
  product?: string | null;
  productReference?: string | null;
  isDetachedFromProduct?: boolean;
  productValues?: Partial<Product>;
  totalAmount?: number;
  operation?: string;
  fixedTaxAmountByClient?: number | null;
  groupName?: string;
  subtotal?: number;
  totalWithoutTaxes?: number;
}

interface TaxExemption {
  // to be defined
  taxExemptionGroup?: string;
  taxExemptionCause?: string;
}

export interface DocumentOwner {
  fiscalName: string;
  vatNumber: string;
  phoneNumber?: string;
  address: Address;
}

export enum DocumentStatus {
  ISSUED = 'ISSUED',
  CORRECTED = 'CORRECTED',
  DRAFT = 'DRAFT',
  VOID = 'VOID',
  TEST = 'TEST',
  REPLACED = 'REPLACED',
}

export enum DocumentPaidStatus {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export enum DocumentKind {
  NORMAL = 'NORMAL',
  RECURRENT = 'RECURRENT',
  EXPENSE_RECURRENT = 'EXPENSE_RECURRENT',
  DRAFT = 'DRAFT',
  TEST = 'TEST',
  PROFORMA = 'PROFORMA',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
}

export enum DocumentType {
  INVOICE = 'INVOICE',
  EXPENSE = 'EXPENSE',
  EXPENSE_RECURRENT = 'EXPENSE_RECURRENT',
  QUOTE = 'QUOTE',
  TEST = 'TEST_INVOICE',
  PROFORMA = 'PROFORMA_INVOICE',
  CORRECTIVE = 'CORRECTIVE',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
}

export interface DocumentTaxes {
  amount: number;
  base: number;
  key: TaxKey;
  percentage: number;
  sales_eq_tax: number;
  sales_eq_tax_percentage: number;
  type: TaxType;
}

export interface AdditionalExpense {
  expense: number | undefined;
  description: string;
  id: string;
}

export interface DocumentTotals {
  amountDue: number;
  discount: number;
  paidAmount: number;
  additionalExpenses: AdditionalExpense[];
  retentionPercentage: number;
  retentions: number;
  salesEqTax: number;
  subtotal: number;
  taxAmount: number;
  taxBase: number;
  taxes: DocumentTaxes[];
  total: number;
  totalExpenses: number;
}

export enum SpecificDocumentSource {
  Shopify = 'shopify',
  Recurrent = 'recurrent',
}

export type DocumentSource = SpecificDocumentSource | Source;

export interface BaseDocument extends Entity {
  additionalExpenses?: AdditionalExpense[];
  code: number;
  identifier: string;
  comments?: string;
  concepts: Concept[];
  contact: Contact;
  currency: Currency;
  dueDate?: string;
  dueDateTime?: string;
  updateAt?: string;
  hasSalesEqualizationTax?: boolean;
  isExpense?: boolean;
  issuedDate?: string;
  hasFile?: boolean;
  issuedDateTime?: string;
  ownerContact?: DocumentOwner;
  paidStatus?: DocumentPaidStatus;
  retentionPercentage?: number;
  serialCode: string;
  // TODO: merge with serialCode, mandatory?
  serie?: DocumentSerie;
  status: DocumentStatus;
  totals: DocumentTotals;
  reference?: string;
  dueDatePeriod?: DocumentPeriodType;
  operationDescription?: string;
  operationDateTime?: string | null;
  taxRegime?: string;
  naturalPerson?: boolean;
  epigraphIAE?: string;
  chargingCriterionIRPF?: boolean;
  customIRPF?: number;
  paidAmount?: number;
  documentType: DocumentType;
  digitalDocument?: DigitalDocument;
  tags?: StatusInfoUI[];
  statusInfo: StatusInfo;
  receiptStatus?: ReceiptStatus;
  paymentMethodsAccepted?: CreatedPaymentMethod[];
  isFreezed: boolean;
  templateId?: string;
  correctedDocuments?: DocumentTable[];
  correctiveData?: CorrectiveData;
  correctiveDocuments?: Document[];
  category?: ExpenseCategories;
  updatedAt?: string;
  source?: DocumentSource;
  lastSource?: DocumentSource;
  lastSentStatus?: LastSentStatus;
  totalAmountFromPayments: number;
  showLinesWithoutTaxes?: boolean;
  totalFixedByClient?: {
    retentionAmount: number;
    total: number;
  };
  version?: number;
}

interface LastSentStatus {
  status?: DocumentSendStatus;
  date?: string;
}

export enum DocumentSendStatus {
  READ = '1-READ',
  RECEIVED = '2-RECEIVED',
  SENT = '3-SENT',
  SPAM = '4-SPAM',
  BLOCKED = '5-BLOCKED',
  BASIC_PLAN_SENT = '6-BASIC_PLAN_SENT',
}

export interface Document extends BaseDocument {}

export enum FileTypeEnum {
  PDF = 'PDF',
}

export interface CorrectiveData {
  reason: string;
  type: string;
  isReplacementFromReceipt: boolean;
}

export enum DocumentToReviewStatusEnum {
  TO_REVIEW = 'TO_REVIEW',
}

export enum InboxFileSentSource {
  EMAIL = 'email',
  UPLOAD = 'upload',
}

export interface DocumentToReview extends Entity {
  arrivalDate: string;
  sentBy: string;
  subject: string;
  fileContentType: FileTypeEnum;
  status: DocumentToReviewStatusEnum;
  sentSource: InboxFileSentSource;
}

export interface DocumentTable extends BaseDocument {
  completeSerialCode?: string;
  notSelectable?: boolean;
}
export interface DocumentTemplate {
  requestId?: string;
  additionalExpenses?: number;
  additionalExpensesDescription?: string;
  comments: string;
  concepts: Concept[];
  contact: string;
  currency: string;
  hasSalesEqualizationTax: boolean;
  paidAmount: number;
  reference?: string;
  retentionPercentage: number;
  serialCode?: string;
  serieId: string;
  showLinesWithoutTaxes?: boolean;
  category?: ExpenseCategories;
  totalFixedByClient?: {
    retentionAmount: number;
    total: number;
  };
}

export enum DocumentPeriodType {
  YEARLY = 'P01Y00M00DT00H00M00S',
  BIANNUAL = 'P00Y06M00DT00H00M00S',
  QUARTERLY = 'P00Y03M00DT00H00M00S',
  BIMONTHLY = 'P00Y02M00DT00H00M00S',
  MONTHLY = 'P00Y01M00DT00H00M00S',
  BIWEEKLY = 'P00Y00M14DT00H00M00S',
  WEEKLY = 'P00Y00M07DT00H00M00S',
  NONE = 'P00Y00M00DT00H00M00S',
}

export enum RecurrentType {
  INVOICE = 'INVOICE',
  EXPENSE = 'EXPENSE',
}

export interface RecurrentDocument extends Entity {
  identifierName?: string;
  type: RecurrentType;
  newDocumentPeriod: DocumentPeriodType;
  dueDocumentPeriod: string;
  initDateTime: string;
  nextDateTime: string;
  documentTemplate: Partial<Document>;
  autoSending?: boolean;
  sendInfo?: DocumentSendInfo;
  paymentMethodsAccepted?: CreatedPaymentMethod[];
  templateId?: string;
  source?: DocumentSource;
  lastSource?: DocumentSource;
  hasFile?: boolean;
  reference?: string;
}

export const isRecurrentDocument = (
  doc: Document | RecurrentDocument
): doc is RecurrentDocument => {
  return (doc as RecurrentDocument).newDocumentPeriod !== undefined;
};

export interface DocumentSendInfo {
  to: string;
  cc?: string[];
  bcc?: string[];
  subject?: string;
  content: string;
  showDetails: boolean;
  recaptcha?: string | null;
}

export type RecurrentDocumentSort = DataTableSort<
  RecurrentDocument,
  keyof RecurrentDocument
>;

export type DocumentSort = DataTableSort<DocumentTable, keyof DocumentTable>;
export type ExpenseToReviewSort = DataTableSort<
  DocumentToReview,
  keyof DocumentToReview
>;

export interface DocumentFilters {
  counterparty?: string;
  searchTerm?: string;
  issuedDateFrom?: string;
  minAmount?: number | string;
  maxAmount?: number | string;
  updatedDateTo?: string;
  updatedDateFrom?: string;
  issuedDateTo?: string;
  operationDateFrom?: string;
  operationDateTo?: string;
  status?: DocumentStatus;
  statusInfo?: StatusInfoUI;
  dueDate?: string;
  tag?: StatusInfoUI;
  code?: number;
  serialCode?: string;
  digitalDocumentStatus?: DigitalDocumentStatus;
  receiptStatus?: ReceiptStatus | null;
  showDateFilters?: boolean;
  showDueDateFilter?: boolean;
  showAmountFilters?: boolean;
  showFooterFilters?: boolean;
}

export interface RecurrentDocumentFilters {
  counterparty?: string;
  searchTerm?: string;
  minAmount?: number | string;
  maxAmount?: number | string;
  nextInvoiceDateFrom?: string;
  nextInvoiceDateTo?: string;
  periodicity?: DocumentPeriodType;
  category?: ExpenseCategories;
}
export interface BaiErrorLanguages {
  ES: string;
  EU: string;
}
export interface TaxAuthorityValidations {
  code: string;
  description: {
    ES: string;
    EU: string;
  };
  internalWarning?: boolean;
}

export enum ValidationErrorType {
  ERROR = 'error',
  WARNING = 'warning',
}
export interface ValidationError {
  code: string;
  description: BaiErrorLanguages;
  extra?: {
    errorType: ValidationErrorType;
  };
}
export interface DigitalDocument {
  id?: string;
  status?: DigitalDocumentStatus;
  failureStatus?: DigitalDocumentFailureStatus;
  taxAuthorityValidations?: TaxAuthorityValidations[];
  validationsErrors?: ValidationError[];
  errors: string[];
  updatedAt?: string;
}

export enum DueDate {
  TODAY = 'TODAY',
  THIS_WEEK = 'WEEK',
  THIS_MONTH = 'MONTH',
  EXPIRED = 'EXPIRED',
}

export enum UploadTypes {
  INVOICE = 'issued',
  EXPENSE = 'expenses',
}

export enum DigitalDocumentStatus {
  PRESENTED = 'PRESENTED',
  PRESENTED_WITH_ERRORS = 'PRESENTED_WITH_ERRORS',
  REJECTED = 'REJECTED',
  FAIL_TO_CONTACT = 'FAIL_TO_CONTACT',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum DigitalDocumentFailureStatus {
  SETUP_FAILURE = 'SETUP_FAILURE',
  PENDING_DOCUMENT_FAILURE = 'PENDING_DOCUMENT_FAILURE',
  TRANSFORMATION_FAILURE = 'TRANSFORMATION_FAILURE',
  SENT_REJECTED = 'SENT_REJECTED',
  SENT_FAILURE = 'SENT_FAILURE',
}

export enum ExpenseCategories {
  Ware = '600_WARE',
  Supplies = '602_SUPPLIES',
  OtherCompanies = '607_OTHER_COMPANIES',
  Returns = '608_RETURNS',
  Renting = '621_RENTING',
  RetentionServices = '623_RETENTION_SERVICES',
  Logistics = '624_LOGISTICS',
  Insurance = '625_INSURANCE',
  BankingExpenses = '626_BANKING_EXPENSES',
  Advertising = '627_ADVERTISING',
  GeneralSupplies = '628_GENERAL_SUPPLIES',
  OtherServices = '629_OTHER_SERVICES',
  Salary = '640_SALARY',
  SocialTaxes = '642_SOCIAL_SECURITY_TAXES',
  SocialExpenses = '649_SOCIAL_EXPENSES',
  BankDebtInterest = '6623_BANK_DEBT_INTEREST',
  TangibleFixedEquipment = '21_TANGIBLE_FIXED_EQUIPMENT',
  Uncategorized = 'UNCATEGORIZED',
  Other = 'OTHER',
  Owed = 'OWED',
}

export type DocumentLocationState = {
  contact: Contact;
  product: Product;
};
