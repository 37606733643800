import { Currency } from 'types/currency';
import { formatISO as formatDateTime } from 'utils/dates';
import { parseToDate } from 'utils/parseToDate';

import { NewDocumentForm } from '../models/form';
import { processBizkaiaData } from './processBizkaiaData';
import { processConcepts } from './processConcepts';

export function normalizeReceiptValues({
  values,
  hasElectronicProvider,
  isPartialCorrective,
}: {
  values: NewDocumentForm;
  hasElectronicProvider: boolean;
  isPartialCorrective?: boolean;
}) {
  const {
    code,
    comments,
    concepts,
    creationDate,
    documentType,
    hasPaidAmount,
    hasSalesEqualizationTax,
    isDefaultDraft,
    operationDateTime,
    operationDescription,
    paidAmount,
    reference,
    serialCode,
    serieId,
    status,
    taxRegime,
    issuedDateTime: formIssuedDateTime,
    currency,
    showProductReference,
  } = values;
  const issuedDateTime =
    formIssuedDateTime ?? (formatDateTime(creationDate) || null);
  const processedConcepts = concepts
    ? processConcepts(concepts, { hasElectronicProvider })
    : [];

  const getProcessedOperationDateTime = () => {
    const date = operationDateTime && parseToDate(new Date(operationDateTime));
    return date ? formatDateTime(date) : null;
  };

  const sendCodeAndDate = !hasElectronicProvider;
  return {
    serieId,
    currency: currency || Currency.EUR,
    paidAmount: hasPaidAmount ? paidAmount : 0,
    serialCode: serialCode || null,
    comments: comments || null,
    status: status || null,
    reference: reference || null,
    concepts: processedConcepts,
    hasSalesEqualizationTax: hasSalesEqualizationTax || false,
    requestId: values.requestId,
    ...(isPartialCorrective && {
      isDefaultDraft,
      id: values.id,
      documentType,
      correctiveCause: values.correctiveCause,
    }),
    ...(hasElectronicProvider && {
      operationDescription,
      operationDateTime: getProcessedOperationDateTime(),
      taxRegime,
      ...processBizkaiaData(values),
    }),
    ...(sendCodeAndDate && {
      code: code || null,
      issuedDateTime,
    }),
    showProductReference,
  };
}
